@import '@/styles/_variables.scss';

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
  visibility: hidden;
  z-index: 999;
  transition: all 0.3s;
  opacity: 0;
  &.active {
    opacity: 1;
    visibility: visible;
    .dialogContent {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  .dialogContent {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 40px, 0);
    transition: all 0.3s 0.2s;
  }
}