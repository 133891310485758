@import '@/styles/_variables.scss';

.snackbarPos {
  z-index: 1000;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 15px); 
  opacity: 0;
  transition: all 0.3s ease;
  &.active {
    transform: translate(-50%);
    opacity: 1;
  }
  .snackbar {
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    border-radius: 4px;
    padding: 16px;
    min-width: 200px;
    align-items: center;    
    color: white;
    background: black;
    position: relative;
    .content {
      margin-right: 16px;
    }
    &.success {
      box-shadow: 0 1px 10px 2px rgba(0,0,0,0.5);
      background: #16a12d;
    }
    &.information {
      background: rgb(36, 120, 230);      
    }
    &.error {
      background: red;
    }
    .close {
      margin-left: auto;
    }
  }
}