@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500;600;700&display=swap');
@import '@/styles/_variables.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Prompt', sans-serif;
  font-size: 14px;  
  min-height: 100%;
  height: 100%;
  --swiper-navigation-color: white;
}

.swiper-button-prev, .swiper-button-next {
  text-shadow: 0px 0px 30px rgba(0,0,0,0.7);
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

#__next {
  height: 100%;
}

.contentWrapper {
  min-height: calc(100% - 255px);
  width: 100%;
}

.max-width {
  max-width: 1280px;
  width: 100%;
  padding: 0 20px;
  margin: auto;
}

img {
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.flex {
  display: flex;
  &.wrap {
    flex-wrap: wrap;
  }
}

.font-bold {
  font-weight: bold;
}

.font-semibold {
  font-weight: 500;
}

img {
  max-width: 100%;
}

.swiper-pagination-bullets {
  display: flex;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  .swiper-pagination-bullet {
    &:only-child {
      display: none;
    }
    min-width: 8px;
    min-height: 8px;
    margin: 6px;
    border-radius: 50%;
    background: rgb(126, 126, 126);
    opacity: 0.45;
    transition: opacity 0.3s;
    &.swiper-pagination-bullet-active {
      opacity: 1;
    }
  }
}

@media (min-width: 768px) {
  .show-xs {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hide-xs {
    display: none !important;
  }  
  body {
    &:not(.no-layout) {
      padding-top: 208px;
    }
  }
}

button, input {
  font-family: inherit;
}
