@import '@/styles/_variables.scss';

.userMenu {
  position: relative;
  cursor: pointer;
  z-index: 10;
  &.active {
    .userMenuList {
      opacity: 1;
      visibility: visible;
      transition: all 0.3s 0.1s;
    }
  }
  .user {
    display: flex;
    align-items: center;
    svg {
      margin-left: 8px;
      width: 20px;
      height: 20px;
    }
  }
  .thumb {
    min-width: 36px;
    max-width: 36px;    
    border-radius: 50%;
    overflow: hidden;
    .ratio {
      position: relative;
      padding-top: 100%;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  hr {
    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid #CCCCCC;
  }
  .userMenuList {
    cursor: initial;
    font-weight: normal;
    white-space: nowrap;
    border-radius: 30px;
    background: white;
    right: 0;
    min-width: 215px;
    max-width: 215px;
    color: black;
    position: absolute;
    top: calc(100% + 15px);
    padding: 20px;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.4);
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s 0.4s;
    .item {
      display: flex;
      align-items: center;
      padding: 6px 0;
      .name {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      img, svg {
        width: 18px;
        height: 18px;
        object-fit: contain;
        margin-right: 10px;
        color: $ci-dim;
      }
      &.hasAction {
        cursor: pointer;
        &:hover {
          color: darken($ci-accent, 10%);
          svg {
            color: darken($ci-accent, 10%);
          }
        }
      }
    }
  }
}

.status {
  margin-left: 10px;
  @include badge-style();
}