@import '@/styles/_variables.scss';

.loadingWrapper {
  z-index: 990;
  background: rgba(255,255,255,0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;  
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  & .path {
    stroke: $ci-accent;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
