@import '@/styles/_variables.scss';

.modal {
  background: white;
  border-radius: 24px;
  overflow: hidden;
  max-width: 500px;
  width: 100%;
  box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.4);
}

.header {
  padding: 20px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}

.body {
  padding: 20px;
}

.action {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.button {
  &.disabled {
    opacity: 0.4;
  }
}