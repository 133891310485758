.switch {
  display: flex;
  cursor: pointer;
  align-items: center;
  &:hover {
    img, svg {
      opacity: 0.8;
    }
  }
  img {
    margin-right: 8px;
    max-width: 32px;
    margin-left: 20px;
  }
  svg {
    width: 14px !important;
    height: 18px;
  }
}
