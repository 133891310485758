@import '@/styles/_variables.scss';

.footer {
  
}
.subscribeWrap {  
  padding: 30px 20px;
  @include ci-gradient(240deg);  
  .subscribeForm {
    display: flex;  
    max-width: 560px;
    width: 100%;
    margin: auto;
  }
  input {
    flex-grow: 1;
    min-width: 0;
    border-radius: 26px;
    font-size: 14px;
    border: none;
    margin: 0;
    padding: 10px 20px;
  }
  @include reset-button();
  .subscribeBtn {
    min-width: 130px;    
    background: white;
    padding: 10px;
    border-radius: 26px;
    margin-left: 8px;
    font-size: 14px;
    color: $text-color;
  }
}
.content {
  color: $text-color;
  padding: 30px;
  text-align: center;
  .title {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .desc {
    max-width: 300px;
    font-weight: 300;
    font-size: 12px;
    margin: auto;
    margin-bottom: 15px;
    display: block;
  }
}
.contact {
  display: flex;
  padding-top: 20px;
  justify-content: center;
  .item {
    margin-left: 5px;
    margin-right: 5px;
    max-width: 40px;
  }
}
.company {
  font-size: 14px;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .subscribeWrap {
    max-width: 100%;
  }
}