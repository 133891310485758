@import '@/styles/_variables.scss';

.input {
  width: 100%;    
  display: flex;
  flex-direction: column;
  position: relative;
  &.invalid {
    input, textarea {
      border-color: $invalid;
    }
  }
  span {
    color: #8C98A9;
    font-size: 16px;
    margin-bottom: 10px;
    width: 100%;
  }
  input, textarea {
    font-size: 0;
    font-family: inherit;
    border: none;
    outline: none;
    border-radius: 4px;
    background: #E0E7FF33;
    border: 1px solid #c7d3fc;
    padding: 10px 15px;
    font-size: 16px;
    width: 100%;
    -webkit-appearance: none;
    resize: none;
    &[disabled] {      
      border: 1px solid #c2c2c2;
      background: #d6d6d6;
      color: #bdbdbd;         
    }
    &:focus {
      border-color: $ci-accent;
    }
  } 
  .wrapper {
    position: relative;
  }
  .error {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    color: $invalid;
    font-size: 10px;
  }
}