.wrapper {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  font-size: 0;
  background: #f0f0f0;  
  position: relative;
  overflow: hidden;  
  width: 100%;
  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &.cover {
    width: 100%;
    img {
      object-fit: cover;
    }
  }
  &[isloaded=true] {
    background: none;
  }
  > div {
    width: 100%;
    height: 100%;
  }
  .smoothImage {
    width: 100%;
    transition: opacity 1s;
  }
  .imageVisible {
    opacity: 1;
  }
  .imageHidden {
    opacity: 0;
  }
  @keyframes load {
    from {
      left: -45%;
    }
    to {
      left: 120%;
    }
  }
  .placeholder {
    width: 100%;
    position: relative;
    padding-top: var(--ratio);
    .loader {
      content: '';
      display: block;
      position: absolute;
      left: -45%;
      top: 0;
      height: 100%;
      width: 45%;
      background: linear-gradient(to right, rgba(240,240,240,0) 0%, rgba(0,0,0,0.5) 50%, rgba(240,240,240,0) 100%);
      animation: load 0.8s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }
  }
}


