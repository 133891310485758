@import '@/styles/_variables.scss';

.page {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100%;
}
.close {
  z-index: 9;
  cursor: pointer;
  position: absolute;
  color: $ci-dim;
  top: 20px;
  left: 20px;
  width: 30px !important;
  height: 30px;
  > svg {
    width: 30px !important;
    height: 30px !important;
  }
}
.column {
  max-width: 100%;
  width: 100%;
  position: relative;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .formWrapper {
    width: 100%;    
    max-width: 480px;
    padding: 20px;
  }
  .cover {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;    
  }
}

@media (max-width: 767px) {
  .column {
    max-width: 100%;
  }
}