@import '@/styles/_variables.scss';

.header {
  padding-top: 30px;
  color: white;
  @include ci-gradient(254deg);
  width: 100%;
  font-weight: 600;
}
.link {
  @include link-animate(white, 2px);
}
.faq {
  @include link-animate(white, 2px);
  font-weight: 400;
}
.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  padding: 0 20px;
  margin: auto;
}
.headerRow {
  display: flex;
  align-items: center;
}
.headerCol {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1 1;
  transition: all 0.3s;
  &.hideOnScroll {
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    max-height: 0;
  }
}
.logo {
  cursor: pointer;
  max-width: 180px;
  margin: auto;
}
.menuList {
  position: relative;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  .menuItem {
    cursor: pointer;
    flex: 1 1;
    display: flex;
    justify-content: center;
    letter-spacing: 0.08em;
    @include link-animate-child();
  }
  .megaMenu {
    z-index: 3;
    position: absolute;
    top: calc(100% + 20px);
    left: 0;
    width: 100%;
    background: white;
    box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    opacity: 0;
    color: #2B2B2B;
    transition: all 0.6s ease;
    max-height: 0px;
    pointer-events: none;
    // overflow: hidden;
    &:before {
      transition: all 0.4s ease;
      content: "";
      position: absolute;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 10px solid white;
      top: -9.5px;
      left: var(--arrow-position);
    }
    &.active {
      pointer-events: initial;
      padding: 30px 40px;
      visibility: visible;
      opacity: 1;
      .column {
        opacity: 1;
      }
    }
    .columnWrap {
      display: flex;
      flex-wrap: wrap;
      height: fit-content;
    }
    .wrapColumn {
      flex: 1;
      .column {
        &:nth-child(2) {
          margin-top: 30px;
        }
      }
    }
    .column {
      flex: 1;
      height: fit-content;
      text-transform: uppercase;
      opacity: 0;
      transition: all 0.2s;
      overflow: hidden;
      &.brand {
        .item {
          max-width: calc(50% - 30px);
        }
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.08em;
        margin-bottom: 16px;
      }
      .listWrap {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 300px;
        transition: all 0.4s;
      }
      .item {
        margin-right: 30px;
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 400;
        max-width: calc(100% - 30px);
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        > div, > a {
          letter-spacing: 0.08em;
          @include link-animate(#2B2B2B, 1px);
        }
      }
    }
  }
}
.action {
  display: flex;
  margin-right: 12px;
  &:last-child {
    margin-right: 0;
  }
  .icon {
    margin-right: 8px;
  }
  .text, .userMenu {
    > * {
      margin-right: 6px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .total {
    font-weight: 400;
    margin-left: 8px;
    color: rgba($color: white, $alpha: 0.5);
  }
}

@media (max-width: 1080px) {
  .hideMd {
    display: none !important;
  }
  .faq {
    flex-grow: 0;
  }
  .menuList {
    .menuItem {
      flex: 0 1 auto;
    }
  }
}

@media (min-width: 768px) {
  .showXs {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .header {
    padding-top: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
  }
  .hideXs {
    display: none !important;
  }
  .logo {
    max-width: 94px;
    flex: 1 1;
    margin: 0;
  }
  .subMenu {
    background: white;
    flex-wrap: nowrap;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 10px;
  }
  .hamburger {
    width: 24px;
    height: 24px;
    // background: white;
    display: flex;
    justify-content: flex-start;
    @include reset-button();
  }
  .user {
    height: 26px;
    display: flex;
    justify-content: flex-end;
    @include reset-button();
    button {
      color: white;
    }
  }
  .headerRow {
    &:nth-child(1) {
      order: 2;
    }
    &:nth-child(2) {
      order: 1;
    }
    &:nth-child(3) {
      order: 3;
    }
  }
  .subMenu {
    overflow-x: auto;
    color: $text-ci;
  }
  .menuList {
    width: auto;
    justify-content: flex-start;
    margin-left: 20px;
    white-space: nowrap;
    flex-wrap: nowrap;
    margin-top: 20px;
    margin-bottom: 20px;
    .menuItem {
      letter-spacing: 0;
      margin-right: 20px;
    }
    .megaMenu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-height: unset !important;
      &.active {
        padding-top: 50px;
      }
      overflow: auto;
      .columnWrap {
        .column {
          margin-bottom: 30px;
          flex: auto;
          width: 100%;
          // .item {
          //   // max-width: 100% !important;
          // }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}