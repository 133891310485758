@import '@/styles/_variables.scss';

.cart {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  padding: 40px 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  visibility: hidden;
  transform: translateX(300px);
  max-width: 300px;
  width: 100%;
  max-height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  height: 100%;
  opacity: 0;
  background: white;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,.5);
  transition: all 0.3s;
  z-index: 999;  
  &.active {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
  .emptyCart {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;    
    color: $ci;
    text-align: center;
  }
}
.dim {
  content: "";
  opacity: 0;
  background: rgba(0,0,0,.6);
  position: fixed;
  top: 0;
  z-index: 998;
  visibility: hidden;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;    
  &.active {
    visibility: visible;
    opacity: 1;
  }
}

.totalContainer {  
  // color: white;
  padding: 20px 0;
  position: sticky;
  margin-top: auto;
  bottom: 0;
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;  
  .total {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    .price {
      float: right;
      text-align: right;
    }
  }  
}

.toCheckOut {
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  display: flex;
  height: 52px;
  min-width: 200px;
  align-items: center;
  background: black;
  margin-top: 20px;
  &:hover {
    // @include ci-gradient();    
    background: lighten(black, 15%);
    // }
  }
  // .bagIcon {
  //   height: 100%;
  //   width: 60px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   background: #E8E8E8;
  // }
  .btnText {
    width: 100%;
    text-align: center;
    color: white;
    padding: 0 20px;
  }
}