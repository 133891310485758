@import '@/styles/_variables.scss';

.searchBox {
  width: 100%;
  border-radius: 38px;
  height: 43px;
  background: white;
  input {
    font-size: 16px;
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    background: none;
    text-align: center;
    font-family: inherit;
    &::placeholder {
      color: $text-color;
    }
    &:focus {
      &::placeholder {
        opacity: 0;
      }
    }
  }
}