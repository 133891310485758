@import '@/styles/_variables.scss';

.canvas {
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  margin: 0;
  padding: 20px;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s ease;
  &.active {
    opacity: 1;
    visibility: visible;
  }
}
.head {

}
.logo {
  position: absolute;
  top: 5px;
  left: 5px;
  max-width: 90px;  
}
.close {
  position: absolute;
  color: $ci-dim;
  top: 30px;
  right: 20px;
  width: 24px !important;
  height: 24px;
}
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: calc(100% - 120px);
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;  
  overflow: auto;
}
.item {
  font-size: 24px;
  color: $ci-dim;
  margin: 15px 0;
}
.bottom {
  position: absolute;
  bottom: 30px;
  right: 20px;
}