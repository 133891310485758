@import '@/styles/_variables.scss';

.cartBtn {
  cursor: pointer;
  position: relative;
  margin-right: 24px;
  transition: all 0.2s cubic-bezier(.47,-0.53,.57,1.7);
  transform-origin: right;
  &.ripple {
    transform: scale(1.2) !important;
  }
}
.icon {
  // margin-right: 8px;
  font-size: 22px;
  position: relative;
  .totalItem {
    position: absolute;
    top: -5px;
    right: -10px;
    font-size: 12px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    // padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $ci-accent;
    color: $ci;
  }
}
.text, .userMenu {
  > * {     
    margin-right: 6px;     
    &:last-child {
      margin-right: 0;
    }
  }
}
.total {
  font-weight: 400;
  margin-left: 8px;    
  color: rgba($color: white, $alpha: 0.5);
}