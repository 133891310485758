$ci-dark: #0C2B3C;
$ci-secondary: #63C0FF;
$ci-dim: #305469;
$ci: #3E647A;
$ci-accent: #EFCB80;
$text-color: #707070;
$text-ci: #385E73;
$grey: #888888;
$invalid: red;
$badge-color: #3b6177;

@mixin ci-gradient($deg: 90deg, $color1: $ci-dark, $color2: $ci) {
  background: linear-gradient($deg, $color1 0%, $color2 100%);
}

@mixin ci-gradient-tri($deg: 120deg, $color1: $ci-dark, $color2: $ci-dim, $color3: $ci) {
  background: linear-gradient($deg, $color1 0%, $color2 50% , $color3 100%);
}

@mixin content-width {
  max-width: 1380px;
  width: 100%;
  margin: auto;
  padding: 0 20px;
}

@mixin link-animate($color: white, $height: 2px) {
  position: relative;
  &:after {
    height: $height;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $color;
    transform: scaleX(0);
    transition: all 0.3s ease;
  }
  &:hover, &.active {
    &:after {
      transform: scaleX(1)
    }
  }
}

@mixin link-animate-child($color: white, $height: 2px) {
  > div, > a {
    position: relative;
    &:after {
      height: $height;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: $color;
      transform: scaleX(0);
      transition: all 0.3s ease;
    }
  }
  &:hover, &.active {
    > div, > a {
      &:after {
        transform: scaleX(1)
      }
    }
  }
}

@mixin line-clamp($line: 2) {
  display: -webkit-box;
  -webkit-box-orient: vertical; 
  overflow: hidden;
  -webkit-line-clamp: $line;  
}

@mixin ellipsis() {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin badge-style {
  display: flex;
  align-items: center;  
  font-size: 12px;
  padding: 4px 6px;
  border-radius: 6px;
  --badge-color: #{$badge-color};
  color: white;
  background: var(--badge-color);  
}

@mixin reset-button {
  button {  
    cursor: pointer;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;
    -webkit-appearance: none;
  }
}